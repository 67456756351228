/* eslint-disable no-trailing-spaces */
/* eslint-disable max-len */
import Vue from 'vue';
import Router from 'vue-router';

import PointView from '@/components/views/Point/PointView/PointView.vue';
import CapacityType from '@/components/NewSettings/NewAssets/CapacityEquipment/CapacityType/CapacityType.vue';
import Brand from '@/components/NewSettings/NewAssets/CapacityEquipment/Brand/Brand.vue';
import Infrastructure from '@/components/NewSettings/NewAssets/CapacityEquipment/Infrastructure/Infrastructure.vue';
import InfrastructureGroup from '@/components/NewSettings/NewAssets/CapacityEquipment/InfrastructureGroup/InfrastructureGroup.vue';
import InfrastructureType from '@/components/NewSettings/NewAssets/CapacityEquipment/InfrastructureType/InfrastructureType.vue';
import ProductFamily from '@/components/NewSettings/NewAssets/CapacityEquipment/ProductFamily/ProductFamily.vue';
import Dashboard from './components/Dashboard.vue';
import Login from './components/Login.vue';
import Logout from './components/Logout.vue';
import Home from './components/views/Home.vue';
import NotFound from './components/404.vue';
import UnauthorizedAccess from './components/401.vue';
import ShiftCalendar from './components/views/ShiftCalendar.vue';
import MaintenancePlanCalendar from './components/NewMaintenanceAndOperations/MaintenancePlanCalendar.vue';
import OrganizationScheme from './components/views/OrganizationScheme.vue';
import Schedule from './components/views/Schedule.vue';
import Procedure from './components/views/Procedure.vue';
import TrainingMaterials from './components/views/TrainingMaterials.vue';
import RaciMatrix from './components/views/RaciMatrix.vue';
import AddWorkOrder from './components/views/AddWorkOrder.vue';
import MinimumStaff from './components/views/MinimumStaff.vue';
import MyWorkOrders from './components/views/MyWorkOrders.vue';
import MyWorkOrder from './components/views/WorkOrder/WorkOrderDetail/WorkOrderDetail.vue';
import RoleTraining from './components/views/RoleTraining.vue';
import TopologicView from './components/views/TopologicView.vue';
import SecurityItem from './components/views/SecurityItem.vue';
import ResetPassword from './components/ResetPassword.vue';
import SecurityRoleItem from './components/views/SecurityRoleItem.vue';
import xyz from './components/views/xyz.vue';
import UserTrainingTracking from './components/views/UserTrainingTracking.vue';
import MassWorkOrderOperations from './components/views/MassWorkOrderOperations.vue';
import MassWorkOrder from './components/views/MassWorkOrder.vue';
import FormAnswers from './components/views/Form/Answers/FormAnswers.vue';
import Warehouse from './components/views/Tool/WarehouseView/Warehouse.vue';
import Tool from './components/views/Tool/ToolView/Tool.vue';
import ToolDetail from './components/views/Tool/ToolDetail/ToolDetail.vue';
import ToolCalendar from './components/views/Tool/ToolCalendar/ToolCalendar.vue';
import PointTreeTest from './components/views/Point/PointTree/PointTreeTest.vue';
import Crud from './scripts/global/Crud.vue';
import Authorization from './components/views/Authorization/Authorization/Authorization.vue';
import Actors from './components/views/Authorization/Actors/Actors.vue';
import ActionGroup from './components/views/Authorization/Actions/ActionGroup/ActionGroup.vue';
import Action from './components/views/Authorization/Actions/Action/Action.vue';
import MethodAuthorization
  from './components/views/Authorization/MethodAuthorize/MethodAuthorization/MethodAuthorization.vue';
import MyWorkOrderTabPage from './components/views/MyWorkOrders/MyWorkOrderTabPage.vue';
import Contract from './components/views/Contract.vue';
import PreventiveMaintenance from './components/views/PreventiveMaintenance/PreventiveMaintenance.vue';
// new pages under the settings tab
import Vendor from './components/NewSettings/NewVendors/Vendor.vue';
import WorkGroup from './components/NewSettings/NewWork/WorkGroup.vue';
import WorkOrderType from './components/NewSettings/NewWorkOrder/WorkOrderType.vue';
import WorkOrderStatusDescription from './components/NewSettings/NewWorkOrder/WorkOrderStatusDescription.vue';

import Model from './components/NewSettings/NewAssets/CapacityEquipment/Model/Model.vue';
import DeviceType from './components/NewSettings/NewAssets/Device/DeviceType.vue';
import MaintenancePeriod from './components/NewSettings/NewAssets/Maintenance/MaintenancePeriod.vue';
import PointDefinition from './components/NewSettings/NewAssets/Point/PointDefinition.vue';
import RoomType from './components/NewSettings/NewAssets/Room/RoomType.vue';
import RoomSubType from './components/NewSettings/NewAssets/Room/RoomSubType.vue';
import ToolType from './components/NewSettings/NewAssets/Tool/ToolType.vue';
import ToolSubType from './components/NewSettings/NewAssets/Tool/ToolSubType.vue';
import UnitDefinition from './components/NewSettings/NewAssets/Unit/UnitDefinition.vue';
import Building from './components/NewSettings/NewAssets/Location/Building.vue';
import Campus from './components/NewSettings/NewAssets/Location/Campus.vue';
import City from './components/NewSettings/NewAssets/Location/City.vue';
import Continent from './components/NewSettings/NewAssets/Location/Continent.vue';
import Country from './components/NewSettings/NewAssets/Location/Country.vue';
import District from './components/NewSettings/NewAssets/Location/District.vue';
import ShiftType from './components/NewSettings/NewAssets/Shift/ShiftType.vue';
import Training from './components/NewSettings/NewOrganization/Training/Training/Training.vue';
import TrainingType from './components/NewSettings/NewOrganization/Training/TrainingType/TrainingType.vue';
import TrainingAssessment from './components/NewSettings/NewOrganization/Training/TrainingAssessment/TrainingAssessment.vue';
import Qualification from './components/NewSettings/NewOrganization/Qualification/Qualification/Qualification.vue';
import QualificationType from './components/NewSettings/NewOrganization/Qualification/QualificationType/QualificationType.vue';
import Job from './components/NewSettings/NewOrganization/Job/Job.vue';
import UserRole from './components/NewSettings/NewOrganization/UserRole/UserRole.vue';
import Constraint from './components/NewSettings/Constraint.vue';
import MaintenancePlan from './components/NewMaintenanceAndOperations/MaintenancePlan.vue';
import PendingMaintenancePlansForAssign from './components/NewMaintenanceAndOperations/PendingMaintenancePlansForAssign.vue';
import User from './components/NewOrganization/User.vue';
import ContractList from './components/NewLibrary/ContractList/ContractList.vue';
import PointAnalysisReport from './components/PointAnalysisReport/PointAnalysisReport.vue';
import WorkOrder from './components/NewTask/WorkOrder.vue';
import Incident from './components/NewTask/Incident.vue';
import PendingApprovalWorkOrder from './components/NewTask/PendingApprovalWorkOrder.vue';
import PendingApprovalIncident from './components/NewTask/PendingApprovalIncident.vue';
import TrainingTracking from './components/NewTraining/TrainingTracking.vue';

import Work from './components/NewWork/Work.vue';
import Form from './components/NewForm/Form.vue';

import SparePartCategory from './components/NewSettings/NewAssets/SparePart/SparePartCategory/SparePartCategory.vue';
import SparePartSubCategory from './components/NewSettings/NewAssets/SparePart/SparePartSubCategory/SparePartSubCategory.vue';
import SparePartType from './components/NewSettings/NewAssets/SparePart/SparePartType/SparePartType.vue';
import SparePartKind from '@/components/NewSettings/NewAssets/SparePart/SparePartKind/SparePartKind.vue';
import SparePartTaskId from '@/components/views/SparePartTaskId.vue';
import DiscardProduct from '@/components/NewSettings/NewAssets/SparePart/DiscardProduct/DiscardProduct.vue';

import SparePart from '@/components/NewSparePart/SparePart.vue';
import SparePartTransfer from '@/components/NewSparePart/SparePartTransfer.vue'

import QAQC from '@/components/NewSettings/NewWork/QAQC.vue'

Vue.use(Router);

export default new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/Login',
      component: Login,
      name: 'Login',
    }, {
      path: '/Logout',
      component: Logout,
      name: 'Logout',
    }, {
      path: '/ResetPassword',
      props: (route) => ({
        token: route.query.token,
      }),
      component: ResetPassword,
      name: 'ResetPassword',
    },
    {
      path: '/',
      component: Dashboard,
      meta: { requiresAuth: true },
      redirect: 'Home',
      children: [
        {
          path: 'Home',
          component: Home,
          name: 'Home',
          meta: {
            requiresAuth: true,
            authItem: 'dashboard_page',
          },
        }, {
          path: 'InventoryList',
          component: xyz,
          name: 'InventoryList',
          meta: {
            xyz: true,
            requiresAuth: true,
            authItem: 'inventory_page',
          },
          props: (route) => ({
            by: 'equipments',
          }),
        }, {
          path: 'MyWorkOrderTabPage',
          component: MyWorkOrderTabPage,
          name: 'MyWorkOrderTabPage',
          meta: {
            requiresAuth: true,
            authItem: 'my_work_order_tab_page',
          },
        }, {
          path: 'Continent',
          component: Continent,
          name: 'Continent',
          // meta: {
          //     requiresAuth: true,
          //     authItem: 'continent_page',
          // },
        }, {
          path: 'Country',
          component: Country,
          name: 'Country',
          meta: {
            requiresAuth: true,
            authItem: 'country_page',
          },
        }, {
          path: 'City',
          component: City,
          name: 'City',
          meta: {
            requiresAuth: true,
            authItem: 'city_page',
          },
        }, {
          path: 'District',
          component: District,
          name: 'District',
          meta: {
            requiresAuth: true,
            authItem: 'district_page',
          },
        }, {
          path: 'Building',
          component: Building,
          name: 'Building',
          meta: {
            requiresAuth: true,
            authItem: 'building_page',
          },
        }, {
          path: 'UserRole',
          component: UserRole,
          name: 'UserRole',
          meta: {
            requiresAuth: true,
            authItem: 'user_role_page',
          },
        }, {
          path: 'Brand',
          component: Brand,
          name: 'Brand',
          meta: {
            requiresAuth: true,
            authItem: 'brand_page',
          },
        }, {
          path: 'Model',
          component: Model,
          name: 'Model',
          meta: {
            requiresAuth: true,
            authItem: 'model_page',
          },
        }, {
          path: 'TrainingType',
          component: TrainingType,
          name: 'TrainingType',
          meta: {
            requiresAuth: true,
            authItem: 'training_type_page',
          },
        }, {
          path: 'Training',
          component: Training,
          name: 'Training',
          meta: {
            requiresAuth: true,
            authItem: 'training_page',
          },
        }, {
          path: 'WorkOrderType',
          component: WorkOrderType,
          name: 'WorkOrderType',
          meta: {
            requiresAuth: true,
            authItem: 'work_order_type_page',
          },
        }, {
          path: 'Job',
          component: Job,
          name: 'Job',
          meta: {
            requiresAuth: true,
            authItem: 'job_page',
          },
        }, {
          path: 'Qualification',
          component: Qualification,
          name: 'Qualification',
          meta: {
            requiresAuth: true,
            authItem: 'qualification_page',
          },
        }, {
          path: 'QualificationType',
          component: QualificationType,
          name: 'QualificationType',
          meta: {
            requiresAuth: true,
            authItem: 'qualification_type_page',
          },
        }, {
          path: 'Infrastructure',
          component: Infrastructure,
          name: 'Infrastructure',
          meta: {
            requiresAuth: true,
            authItem: 'infrastructure_page',
          },
        }, {
          path: 'InfrastructureType',
          component: InfrastructureType,
          name: 'InfrastructureType',
          meta: {
            requiresAuth: true,
            authItem: 'infrastructure_type_page',
          },
        }, {
          path: 'CapacityType',
          component: CapacityType,
          name: 'CapacityType',
          meta: {
            requiresAuth: true,
            authItem: 'capacity_type_page',
          },
        }, {
          path: 'ProductFamily',
          component: ProductFamily,
          name: 'ProductFamily',
          meta: {
            requiresAuth: true,
            authItem: 'product_family_page',
          },
        }, {
          path: 'ShiftType',
          component: ShiftType,
          name: 'ShiftType',
          meta: {
            requiresAuth: true,
            authItem: 'shift_type_page',
          },
        }, {
          path: 'ShiftCalendar',
          component: ShiftCalendar,
          name: 'ShiftCalendar',
          meta: {
            requiresAuth: true,
            authItem: 'shift_calendar_page',
          },
        }, {
          path: 'Vendor',
          component: Vendor,
          name: 'Vendor',
          meta: {
            requiresAuth: true,
            authItem: 'vendor_page',
          },
        }, {
          path: 'User',
          component: User,
          name: 'User',
          meta: {
            requiresAuth: true,
            authItem: 'user_page',
            user: true,
          },
        }, {
          path: 'MaintenancePlan',
          component: MaintenancePlan,
          name: 'MaintenancePlan',
          meta: {
            requiresAuth: true,
            authItem: 'maintenance_page',
          },
        }, {
          path: 'MaintenancePeriod',
          component: MaintenancePeriod,
          name: 'MaintenancePeriod',
          meta: {
            requiresAuth: true,
            authItem: 'maintenance_period_page',
          },
        }, {
          path: 'RoomType',
          component: RoomType,
          name: 'RoomType',
          meta: {
            requiresAuth: true,
            authItem: 'room_type_page',
          },
        }, {
          path: 'RoomSubType',
          component: RoomSubType,
          name: 'RoomSubType',
          meta: {
            requiresAuth: true,
            authItem: 'room_sub_type_page',
          },
        }, {
          path: 'Campus',
          component: Campus,
          name: 'Campus',
          meta: {
            requiresAuth: true,
            authItem: 'campus_page',
          },
        }, {
          path: 'MaintenancePlanCalendar',
          component: MaintenancePlanCalendar,
          name: 'MaintenancePlanCalendar',
          meta: {
            requiresAuth: true,
            authItem: 'maintenance_plan_calendar_page',
          },
        }, {
          path: 'OrganizationScheme',
          component: OrganizationScheme,
          name: 'OrganizationScheme',
          meta: {
            requiresAuth: true,
            authItem: 'organization_scheme_page',
          },
        }, {
          path: 'Work',
          component: Work,
          name: 'Work',
          meta: {
            requiresAuth: true,
            authItem: 'work_page',
          },
        }, {
          path: 'WorkOrder',
          component: WorkOrder,
          name: 'WorkOrder',
          meta: {
            requiresAuth: true,
            authItem: 'work_order_page',
          },
          props: (route) => ({
            workOrderStatusId: route.query.workOrderStatusId,
            startDate: route.query.startDate,
            endDate: route.query.endDate,
          }),
        }, {
          path: 'PendingMaintenancePlansForAssign',
          component: PendingMaintenancePlansForAssign,
          name: 'PendingMaintenancePlansForAssign',
          meta: {
            requiresAuth: true,
            authItem: 'pending_maintenance_plans_for_assign_page',
          },
          props: (route) => ({
            startDate: route.query.startDate,
            endDate: route.query.endDate,
          }),
        }, {
          path: 'Schedule',
          component: Schedule,
          name: 'Schedule',
          meta: {
            requiresAuth: true,
            authItem: 'schedule_page',
          },
        }, {
          path: 'Procedure',
          component: Procedure,
          name: 'Procedure',
          meta: {
            requiresAuth: true,
            authItem: 'procedure_page',
          },
        }, {
          path: 'Constraint',
          component: Constraint,
          name: 'Constraint',
          meta: {
            requiresAuth: true,
            authItem: 'constraint_page',
          },
        },
        {
          path: 'TrainingMaterials',
          component: TrainingMaterials,
          name: 'TrainingMaterials',
          meta: {
            requiresAuth: true,
            authItem: 'training_materials_page',
          },
        }, {
          path: 'TrainingAssessment',
          component: TrainingAssessment,
          name: 'TrainingAssessment',
          meta: {
            requiresAuth: true,
            authItem: 'training_assessment_page',
          },
        }, {
          path: 'RaciMatrix',
          component: RaciMatrix,
          name: 'RaciMatrix',
          meta: {
            requiresAuth: true,
            authItem: 'raci_matrix_page',
          },
        }, {
          path: 'MinimumStaff',
          component: MinimumStaff,
          name: 'MinimumStaff',
          meta: {
            requiresAuth: true,
            authItem: 'minimum_staff_page',
          },
        }, {
          path: 'WorkGroup',
          component: WorkGroup,
          name: 'WorkGroup',
          meta: {
            requiresAuth: true,
            authItem: 'work_group_page',
          },
        }, {
          path: 'AddWorkOrder',
          props: (route) => ({
            workOrderId: route.query.workOrderId,
            maintenanceGroupId: route.query.maintenanceGroupId,
          }),
          component: AddWorkOrder,
          name: 'AddWorkOrder',
          meta: {
            requiresAuth: true,
            authItem: 'add_work_order_page',
          },
        }, {
          path: 'MyWorkOrder',
          props: (route) => ({
            workOrderId: route.query.workOrderId,
            from: route.query.from,
          }),
          component: MyWorkOrder,
          name: 'MyWorkOrder',
          meta: {
            requiresAuth: true,
            authItem: 'my_work_order_page',
          },
        }, {
          path: 'InfrastructureGroup',
          component: InfrastructureGroup,
          name: 'InfrastructureGroup',
          meta: {
            requiresAuth: true,
            authItem: 'infrastructure_group_page',
          },
        }, {
          path: 'MyWorkOrders',
          component: MyWorkOrderTabPage,
          name: 'MyWorkOrders',
          meta: {
            requiresAuth: true,
            authItem: 'my_work_orders_page',
          },
        }, {
          path: 'RoleTraining',
          component: RoleTraining,
          name: 'RoleTraining',
          meta: {
            requiresAuth: true,
            authItem: 'role_training_page',
          },
        }, {
          path: 'UserTrainingTracking',
          component: UserTrainingTracking,
          name: 'UserTrainingTracking',
          meta: {
            requiresAuth: true,
            authItem: 'user_training_tracking_page',
          },
        },
       /* {
          path: 'TrainingTracking',
          component: TrainingTracking,
          name: 'TrainingTracking',
          meta: {
            requiresAuth: true,
            authItem: 'training_tracking_page',
          },
        },*/ {
          path: 'SecurityItem',
          component: SecurityItem,
          name: 'SecurityItem',
          meta: {
            requiresAuth: true,
            authItem: 'security_item_page',
          },

        }, {
          path: 'SecurityRoleItem',
          component: SecurityRoleItem,
          name: 'SecurityRoleItem',
          meta: {
            requiresAuth: true,
            authItem: 'security_role_item_page',
          },
        }, {
          path: 'Forms',
          component: Form,
          name: 'Forms',
          meta: {
            requiresAuth: true,
            authItem: 'forms_page',
          },
        }, {
          path: 'RoomCapacityEquipment',
          props: (route) => ({
            by: route.query.by,
          }),
          component: xyz,
          name: 'xyz',
          meta: {
            requiresAuth: false,
            authItem: 'room_capacity_equipment_page',
            xyz: true,
          },
        }, {
          path: 'MassMaintenancePlanOperations',
          component: MassWorkOrderOperations,
          name: 'MassMaintenancePlanOperations',
          props: (route) => ({
            massWorkOrderId: route.query.massWorkOrderId,
            from: route.query.from,
          }),
          meta: {
            requiresAuth: true,
            authItem: 'mass_work_order_operations_page',
          },
        }, {
          path: 'MassMaintenancePlan',
          component: MassWorkOrder,
          name: 'MassMaintenancePlan',
          meta: {
            requiresAuth: true,
            authItem: 'mass_work_order_page',
          },
        }, {
          path: 'PendingApprovalWorkOrder',
          component: PendingApprovalWorkOrder,
          name: 'PendingApprovalWorkOrder',
          meta: {
            requiresAuth: true,
            authItem: 'pending_approval_work_order_page',
          },
        },  {
          path: 'PendingApprovalIncident',
          component: PendingApprovalIncident,
          name: 'PendingApprovalIncident',
          meta: {
            requiresAuth: true,
            authItem: 'pending_approval_incident_page',
          }
        }, {
          path: 'WorkOrderStatusDescription',
          component: WorkOrderStatusDescription,
          name: 'WorkOrderStatusDescription',
          meta: {
            requiresAuth: true,
            authItem: 'work_order_status_description_page',
          },
        }, {
          path: 'FormAnswers',
          component: FormAnswers,
          name: 'FormAnswers',
          meta: {
            requiresAuth: true,
            authItem: 'form_answers_page',
          },
          props: (route) => ({
            parentFormId: route.query.parentFormId,
            version: route.query.version,
          }),
        }, {
          path: 'ToolType',
          component: ToolType,
          name: 'ToolType',
          meta: {
            requiresAuth: true,
            authItem: 'tool_type_page',
          },
        }, {
          path: 'ToolSubType',
          component: ToolSubType,
          name: 'ToolSubType',
          meta: {
            requiresAuth: true,
            authItem: 'tool_sub_type_page',
          },
        }, {
          path: 'Warehouse',
          component: Warehouse,
          name: 'Warehouse',
          meta: {
            requiresAuth: true,
            authItem: 'warehouse_page',
          },
        }, {
          path: 'Tool',
          component: Tool,
          name: 'Tool',
          meta: {
            requiresAuth: true,
            authItem: 'tool_page',
          },
        }, {
          path: 'ToolCalendar',
          component: ToolCalendar,
          name: 'ToolCalendar',
          meta: {
            requiresAuth: true,
            authItem: 'tool_calendar_page',
          },
        }, {
          path: 'ToolDetail',
          component: ToolDetail,
          name: 'ToolDetail',
          meta: {
            requiresAuth: true,
            authItem: 'tool_detail_page',
          },
          props: (route) => ({
            toolId: route.query.toolId,
          }),
        }, {
          path: 'PointView',
          component: PointView,
          name: 'PointView',
          meta: {
            requiresAuth: true,
            authItem: 'point_view_page',
          },
        }, {
          path: 'PointDefinition',
          component: PointDefinition,
          name: 'PointDefinition',
          meta: {
            requiresAuth: true,
            authItem: 'point_definition_page',
          },
        }, {
          path: 'PointTree',
          component: PointTreeTest,
          name: 'PointTree',
          meta: {
            requiresAuth: true,
            authItem: 'point_tree_page',
          },
        },
        {
          path: 'UnitDefinition',
          component: UnitDefinition,
          name: 'UnitDefinition',
          meta: {
            requiresAuth: true,
            authItem: 'unit_definition_page',
          },
        }, {
          path: 'DeviceType',
          component: DeviceType,
          name: 'DeviceType',
          meta: {
            requiresAuth: true,
            authItem: 'device_type_page',
          },
        },
        {
          path: 'PreventiveMaintenance',
          component: PreventiveMaintenance,
          name: 'PreventiveMaintenance',
          meta: {
            requiresAuth: true,
            authItem: 'preventive_maintenance_page',
          },
        }, {
          path: 'Crud',
          component: Crud,
          name: 'Crud',
          meta: {
            requiresAuth: true,
            authItem: 'crud_page',
          },
          props: (route) => ({
            dto: route.query.dto,
            field: route.query.field,
            value: route.query.value,
          }),
        }, {
          path: 'Authorization',
          component: Authorization,
          name: 'Authorization',
          meta: {
            requiresAuth: true,
            authItem: 'authorization_page',
          },
        }, {
          path: 'MethodAuthorization',
          component: MethodAuthorization,
          name: 'MethodAuthorization',
          meta: {
            requiresAuth: true,
            authItem: 'method_authorization_page',
          },
        }, {
          path: 'Actors',
          component: Actors,
          name: 'Actors',
          meta: {
            requiresAuth: true,
            authItem: 'actors_page',
          },
          props: (route) => ({
            actorId: route.query.id,
            from: route.query.from,
          }),
        }, {
          path: 'ActionGroup',
          component: ActionGroup,
          name: 'ActionGroup',
          meta: {
            requiresAuth: true,
            authItem: 'action_group_page',
          },
        }, {
          path: 'Action',
          component: Action,
          name: 'Action',
          meta: {
            requiresAuth: true,
            authItem: 'action_page',
          },
        }, {
          path: 'ContractList',
          component: ContractList,
          name: 'ContractList',
          meta: {
            requiresAuth: true,
            authItem: 'contract_list_page',
          },
        },{
          path:"PointAnalysisReport",
          component: PointAnalysisReport,
          name:"PointAnalysisReport"
        }, {
          path: 'Contract',
          component: Contract,
          name: 'Contract',
          meta: {
            requiresAuth: true,
            authItem: 'contract_definition_page',
            contract: true,
          },
          props: (route) => ({
            Id: route.query.Id,
            IsEditable: route.params.IsEditable,
            // query olarakta gönderilebilir!
          }),
        }, {
          path: 'Incident',
          component: Incident,
          name: 'Incident',
          meta: {
            requiresAuth: true,
            authItem: 'incident_page',
          },
          props: (route) => ({
            workOrderStatusId: route.query.workOrderStatusId,
          }),
        }, 
        // TODO : authItem will be changed 
        {
          path: 'SparePart',
          component: SparePart,
          name: 'SparePart',
          meta: {
            requiresAuth: true,
            authItem: 'spare_part_page',
          },
        },
        {
          path: 'SparePartTransfer',
          props: (route) => ({
            sparePartId: route.query.sparePartId,
            from: route.query.from,
          }),
          component: SparePartTransfer,
          name: 'SparePartTransfer',
          meta: {
            requiresAuth: true,
            authItem: 'spare_part_page',
          },
        },
        {
          path: 'SparePartCategory',
          component: SparePartCategory,
          name: 'SparePartCategory',
          meta: {
            requiresAuth: true,
            authItem: 'spare_part_category_page',
          },
        }, {
          path: 'SparePartSubCategory',
          component: SparePartSubCategory,
          name: 'SparePartSubCategory',
          meta: {
            requiresAuth: true,
            authItem: 'spare_part_sub_category_page',
          },
        }, {
          path: 'SparePartType',
          component: SparePartType,
          name: 'SparePartType',
          meta: {
            requiresAuth: true,
            authItem: 'spare_part_type_page',
          },
        },{
          path: 'SparePartTaskId',
          component: SparePartTaskId,
          name: 'SparePartTaskId',
          meta: {
            requiresAuth: true,
            authItem: 'spare_part_task_id_page',
          },
        }, {
          path: 'SparePartKind',
          component: SparePartKind,
          name: 'SparePartKind',
          meta: {
            requiresAuth: true,
            authItem: 'spare_part_kind_page',
          },
        },
        {
          path: 'DiscardProduct',
          component: DiscardProduct,
          name: 'DiscardProduct',
          meta: {
            requiresAuth: true,
            authItem: 'discard_product_page',
          },
        },
        {
          path: 'QAQC',
          component: QAQC,
          name: 'QAQC',
          meta: {
            requiresAuth: true,
            authItem: 'qaqc_page',
          }
        },
      ],
    },
    {
      path: '*',
      redirect: '/404',
    },
    {
      // not found handler
      path: '/404',
      component: NotFound,
    },
    {
      path: '/401',
      name: 'UnauthorizedAccess',
      component: UnauthorizedAccess,
    }, /*
        {
            path: '/continent2',
            name: 'continent2',
            component: () => import('./views/location/continent/Continent.vue'),
        }, */
  ],
});
