





















































































































































// @ts-nocheck
import { ScheduleComponent, Day, Week, Month, DragAndDrop, ExcelExport, Print } from '@syncfusion/ej2-vue-schedule';
import { AccordionComponent } from '@syncfusion/ej2-vue-navigations';
import * as api from '@/scripts/services/api'
import MaintenancePlanDialog from './MaintenancePlanDialog.vue'
import { MultiSelectComponent } from '@syncfusion/ej2-vue-dropdowns'
import MaintenancePlanMultiUpdate from './MaintenancePlanMultiUpdate.vue'
import { OtherApi } from '@/scripts/services/OtherApis'
import * as swal from '@/helpers/swal'
import * as dateFormat from '../../helpers/dateFormat';
import ExcelJS from 'exceljs';

export default {
  components: {
    'ejs-schedule': ScheduleComponent,
    'maintenance-plan-dialog': MaintenancePlanDialog,
    'ejs-multiselect': MultiSelectComponent,
    'maintenance-plan-multi-update': MaintenancePlanMultiUpdate,
    'ejs-accordion': AccordionComponent,
  },
  props: {
    defaultStartDate: {
      type: String,
      default: undefined
    },
    defaultEndDate: {
      type: String,
      default: undefined
    }
  },
  data() {
    return {
      filterNameForSave: "",
      savedFilters: [],
      apiClient: new OtherApi<any>(),
      isDialogActive: false,
      isMultiUpdateDialogActive: false,
      createStartDate: null,
      selectedId: 0,
      selectedMaintenancePlan: [],
      loading: false,
      timeFormat: 'HH:mm',
      startDate: new Date(),
      endDate: new Date(),
      defaultDate: new Date(),
      searchText: '',
      workOrderStatuses: [],
      selectedWorkOrderStatuses: [],
      userList: [],
      selectedUserList: [],
      deleteBtnShow: false,
      eventSettings: {
        dataSource: [
          // {
          //     Id: 1,
          //     FullName: 'Meeting',
          //     ScheduledTime: new Date().toISOString(),
          //     ExpectedEndTime: new Date(new Date().setHours(new Date().getHours()+1)).toISOString(),
          // }
        ],
        fields: {
          id: 'Id',
          subject: { name: 'FullName' },
          startTime: { name: 'ScheduledTime' },
          endTime: { name: 'ExpectedEndTime' },
          location: {name: 'DeviceName'}
        },
      },
      views: ['Day', 'Week', 'Month'],
      currentView: 'Month',
      timezone: 'Europe/Istanbul',
      firstDayOfWeek: 1,
      translations: {
        search: "Search",
        status: "Status",
        user: "User",
        select: "Select",
        deselect: "Deselect",
        selected: "Selected",
        filter: "Filter",
        filterSave: "Filter Save",
        savedFilters: "Saved Filters",
        filterName: "Filter Name",
        save: "Save",
        apply: "Apply",
        delete: "Delete"
      },
      scheduleTranslations: {
        Monday: 'Pazartesi',
        Tuesday: 'Salı',
        Wednesday: 'Çarşamba',
        Thursday: 'Perşembe',
        Friday: 'Cuma',
        Saturday: 'Cumartesi',
        Sunday: 'Pazar',
        Jan: 'Oca',
        Feb: 'Şub',
        Mar: 'Mar',
        Apr: 'Nis',
        Jun: 'Haz',
        Jul: 'Tem',
        Aug: 'Ağu',
        Sep: 'Eyl',
        Oct: 'Eki',
        Nov: 'Kas',
        Dec: 'Ara',
        Mon: 'Pzt',
        Tue: 'Sal',
        Wed: 'Çar',
        Thu: 'Per',
        Fri: 'Cum',
        Sat: 'Cmt',
        Sun: 'Paz',
        January: 'Ocak',
        February: 'Şubat',
        March: 'Mart',
        April: 'Nisan',
        June: 'Haziran',
        July: 'Temmuz',
        August: 'Ağustos',
        September: 'Eylül',
        October: 'Ekim',
        November: 'Kasım',
        December: 'Aralık',
        Today: 'Bugün',
        Day: 'Gün',
        Week: 'Hafta',
        MONTH: 'Ay',
        Year: 'Yıl',
        'All day': 'Tüm gün',
        Save: 'Kaydet',
        Cancel: 'İptal',
        Delete: 'Sil',
        Edit: 'Düzenle',
        Event: 'Etkinlik',
        'No events': 'Etkinlik yok',
        'Event Details': 'Etkinlik Detayları',
        'Add Event': 'Etkinlik Ekle',
      },
    }
  },
  provide: {
    schedule: [Day, Week, Month, DragAndDrop, ExcelExport, Print],
  },
  watch: {
    'eventSettings.dataSource': {
      handler(newVal: any, oldVal: any) {},
      deep: true,
    },
  },

  mounted() {
    if(this.$route.query.defaultStartDate && this.$route.query.defaultEndDate){
      this.currentView = "Day";
      this.defaultDate = new Date(Number(this.$route.query.defaultStartDate));
      this.startDate = new Date(Number(this.$route.query.defaultStartDate));
      this.endDate = new Date(Number(this.$route.query.defaultEndDate));
    }else {
      const schedule = this.$refs.ScheduleObj.ej2Instances;
      const visibleDates = schedule.getCurrentViewDates();
      this.startDate = new Date(visibleDates[0].getTime()) ;
      this.endDate = new Date(visibleDates[visibleDates.length - 1].getTime() + 86400000);
    }
    this.getSavedFilters();
    //@ts-ignore
    this.getData()
    //@ts-ignore
    this.getWorkOrderStatuses()
    //@ts-ignore
    this.getUserList()
    this.translate() // İlk çeviri işlemi
    // MutationObserver ile DOM değişikliklerini izleyin
    const observer = new MutationObserver(() => {
      clearTimeout(this.translationTimeout) // Eğer daha önce başlatılmış bir timeout varsa temizle
      this.translationTimeout = setTimeout(() => {
        this.translate() // Gecikmeli çeviri işlemi
      }, 500) // 500ms gecikme
    })

    observer.observe(document.body, { childList: true, subtree: true }) // Tüm sayfayı izleyin
    this.observer = observer // observer'ı kaydediyoruz ki bileşen yok edilmeden önce durdurabilelim
  },
  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect() // Bileşen yok edilmeden önce gözlemlemeyi durdur
    }
    if (this.translationTimeout) {
      clearTimeout(this.translationTimeout) // Timeout'u temizle
    }
  },
  methods: {
    async filterSave() {
      if(this.filterNameForSave === ""){
        if(localStorage.getItem("vue-lang") === "tr-TR"){
          swal.error("Filtreye bir isim veriniz")
        }else {
          swal.error("Give a name to the filter")
        }
        return;
      }
      let filterData = {
        filterName: this.filterNameForSave,
        searchText: this.searchText.trim(),
        selectedWorkOrderStatuses: this.selectedWorkOrderStatuses,
        selectedUserList: this.selectedUserList
      }
      let response = (await api.preventiveMaintenanceScheduleSaveFilter({FilterText:JSON.stringify(filterData)})).body;
      if(response.IsSuccess){
        this.filterNameForSave = "";
        if(localStorage.getItem("vue-lang") === "tr-TR"){
          swal.success("Kaydedildi","Filtre başarılı bir şekilde kaydedildi");
        }else{
          swal.success("Saved","Filter saved successfully");
        }
        this.getSavedFilters();
      }else{
        swal.error("Error")
      }
    },
    async getSavedFilters(){
      let response = (await api.preventiveMaintenanceScheduleGetFilters()).body;
      this.savedFilters = response;
    },
    filterUse(filter:any){
      let parsedFilter = JSON.parse(filter.FilterText);
      this.searchText = parsedFilter.searchText.trim();
      this.selectedWorkOrderStatuses = parsedFilter.selectedWorkOrderStatuses;
      this.selectedUserList = parsedFilter.selectedUserList;
      //@ts-ignore
      this.getData()
    },
    async filterDelete(filter:any){
      let response = (await api.preventiveMaintenanceScheduleDeleteFilter(filter.Id)).body;
      if(response.IsSuccess){
        this.getSavedFilters();
        if(localStorage.getItem("vue-lang") === "tr-TR"){
          swal.success("Filtre Silindi")
        }else{
          swal.success("Filter Deleted")
        }
      }else{
        swal.error("Error")
      }
    },
    translate() {
      if(localStorage.getItem("vue-lang") === "tr-TR"){
        // scheduleTranslations nesnesindeki yalnızca ay isimleri için işlem yap
        Object.entries(this.scheduleTranslations).forEach(([key, value]) => {
          // İngilizce ay ismini içeren span veya button öğelerini bul
          const xpath = `//span[contains(text(),'${key}')] | //button[contains(text(),'${key}')] | //div[contains(text(),'${key}')]`
          const elements = document.evaluate(xpath, document, null, XPathResult.ORDERED_NODE_SNAPSHOT_TYPE, null)

          // Bulunan her öğede metnin sadece ay ismini çevir
          for (let i = 0; i < elements.snapshotLength; i++) {
            const element = elements.snapshotItem(i) as HTMLElement
            if (element) {
              // Ay ismini koruyarak yılı değiştirme
              element.innerText = element.innerText.replace(new RegExp(`\\b${key}\\b`, 'gi'), value)
            }
          }
        })
        this.translations.search = "Ara";
        this.translations.status = "Durum";
        this.translations.user = "Kullanıcı Seç";
        this.translations.select = "Seç";
        this.translations.selected = "Seçildi";
        this.translations.deselect = "Seçimi Kaldır";
        this.translations.filter = "Filtre";
        this.translations.filterSave = "Filtre Kayıt";
        this.translations.savedFilters = "Kayıtlı Filtreler";
        this.translations.filterName = "Filte Adı";
        this.translations.save = "Kaydet";
        this.translations.apply = "Uygula";
        this.translations.delete = "Sil";
      }
    },

    getElementByXpath(path: string) {
      return document.evaluate(path, document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue
    },
    async getData() {
      //@ts-ignore
      this.loading = true
      let now = new Date()
      let response = (
        await api.GetPreventiveMaintenanceSchedules({
          //@ts-ignore
          StartDate: this.startDate.toISOString(),
          //@ts-ignore
          EndDate: this.endDate.toISOString(),
          //@ts-ignore
          SearchText: this.searchText ? this.searchText.trim() : null,
          //@ts-ignore
          Statuses: this.selectedWorkOrderStatuses ? this.selectedWorkOrderStatuses.map((item) => item.Value) : null,
          //@ts-ignore
          Personals: this.selectedUserList ? this.selectedUserList.map((item) => item.NameSurname) : null,
        })
      ).body
      //@ts-ignore
      this.eventSettings.dataSource = response
      //@ts-ignore
      this.eventSettings = { ...this.eventSettings } // UI'nin yeniden render edilmesi için
      //@ts-ignore
      this.loading = false
    },
    onCellClick: function (args: any) {
      args.cancel = true
    },
    onCellDoubleClick: async function (args: any) {
      args.cancel = true
      //@ts-ignore
      this.deleteBtnShow = false;
      //@ts-ignore
      this.createStartDate = args.startTime
      //@ts-ignore
      this.selectedId = 0
      //@ts-ignore
      this.isDialogActive = true
      //@ts-ignore
      await this.$nextTick()
      //@ts-ignore
      ;(<MaintenancePlanDialog>this.$refs.maintenancePlanDialog).showDialog()
    },
    popupOpen: async function (args: any) {
      if (args.type === 'QuickInfo' && args.data) {
        args.cancel = true
      }
      if (args.type === 'Editor' && args.data) {
        args.cancel = true
        if (args.data.StatusId === 1 || args.data.StatusId === 2) {
          // //@ts-ignore
          // this.selectedMaintenancePlan = [args.data]
          // //@ts-ignore
          // this.createStartDate = null
          // //@ts-ignore
          // this.isMultiUpdateDialogActive = true
          // //@ts-ignore
          // await this.$nextTick()
          // //@ts-ignore
          // ;(<MaintenancePlanMultiUpdate>this.$refs.maintenancePlanMultiUpdate).showDialog()
          //@ts-ignore
          this.selectedId = args.data.Id
          //@ts-ignore
          this.deleteBtnShow = true;
          //@ts-ignore
          this.createStartDate = null
          //@ts-ignore
          this.isDialogActive = true
          //@ts-ignore
          await this.$nextTick()
          //@ts-ignore
          ;(<MaintenancePlanDialog>this.$refs.maintenancePlanDialog).showDialog()
        } else {
          args.cancel = true;
          if(localStorage.getItem("vue-lang") === "tr-TR"){
            swal.error("Error","Planlandı ve Atama Yapıldı statüsü harici düzenleme yapılamaz.")
          }else{
            swal.error("Error","No edits can be made except for the Planned and Assigned status.")
          }
        }
      }
    },
    onNavigating: function (args: any) {
      this.handleNavigate(args)
    },
    async handleNavigate(args: any){
      await this.$nextTick();
      if (args.action === 'date' || args.action === "view") {
        const schedule = this.$refs.ScheduleObj.ej2Instances;
        const visibleDates = schedule.getCurrentViewDates();
        this.startDate = new Date(visibleDates[0].getTime()) ;
        this.endDate = new Date(visibleDates[visibleDates.length - 1].getTime() + 86400000);
        this.getData()
      }
    },
    onEventRender(args: any) {
      const event = args.data // Etkinlik verisi
      if (event.ColorCode) {
        // Etkinlikte ColorCode varsa, etkinliğin stilini değiştiriyoruz
        args.element.style.backgroundColor = event.ColorCode
      }
      //@ts-ignore
    },
    handleSearchClick() {
      //@ts-ignore
      this.getData()
    },
    async getWorkOrderStatuses() {
      let response = (await api.getWorkOrderStatuses(1)).body
      //@ts-ignore
      this.workOrderStatuses = response
    },
    async getUserList() {
      let response = (await api.getUserListWithoutIncludes()).body
      //@ts-ignore
      this.userList = response
    },
    async onDragStop(args: any) {
      if(args.data.StatusId === 1 || args.data.StatusId === 2){
        //@ts-ignore
        let response = (await api.maintenancePlanGetById(args.data.Id)).body
        let updateReq = {
          Id: args.data.Id,
          ContractId: response.ContractId,
          Duration: response.Duration,
          MaintenanceTypeId: response.OrderType ? response.OrderType.Id : null,
          Note: response.Note,
          StartDate: args.data.ScheduledTime.toISOString(),
          VendorId: response.VendorId,
          VendorNotificationRemainingDay: response.VendorNotificationRemainingDay,
        }

        let updateResponse = (await api.updateMaintenancePlan(updateReq)).body
        if (updateResponse.IsSuccess) {
          if(localStorage.getItem("vue-lang") === "tr-TR"){
            swal.success('Kayıt Güncellendi')
          }else{
            swal.success('Updated')
          }
        }
      }else{
        args.cancel = true;
        if(localStorage.getItem("vue-lang") === "tr-TR"){
          swal.error("Error","Planlandı ve Atama Yapıldı statüsü harici düzenleme yapılamaz.")
        }else{
          swal.error("Error","No edits can be made except for the Planned and Assigned status.")
        }
      }
      
    },
    onActionBegin: function (args: any) {
      if (args.requestType === 'toolbarItemRendering') {
        let exportItemExcel = {
          align: 'Left',
          showTextOn: 'Both',
          text: localStorage.getItem("vue-lang") === "tr-TR" ? 'Excel Dışa Aktar' : 'Excel Export',
          cssClass: 'e-excel-export',
          click: this.onExportClick.bind(this),
        }
        args.items.push(exportItemExcel)
        let exportItemPrint = {
          align: 'Left',
          showTextOn: 'Both',
          text: localStorage.getItem("vue-lang") === "tr-TR" ? 'Yazdır' : 'Print',
          cssClass: 'e-print',
          click: this.onPrintIconClick.bind(this),
        }
        args.items.push(exportItemPrint);

        let addMaintenance = {
          align: 'Left',
          showTextOn: 'Both',
          text:    ' <i class="fa fa-plus"></i>' + 
            (localStorage.getItem("vue-lang") === "tr-TR" ? 'Ekle' : 'Add'), 
          cssClass: 'add-text', 
          click: this.onAddMaintenanceClick.bind(this),
        };

        args.items.push(addMaintenance);

        const style = document.createElement("style");
        style.textContent = `
          .add-text {
            font-weight: bold;
            display: flex;
            align-items: center;
          }
          .add-text i {
            margin-right: 5px;
          }
        `;
      document.head.appendChild(style);
      }
    },
    onAddMaintenanceClick : function () {
      this.handleAddMaintenanceClick();
    },
    async handleAddMaintenanceClick() {
      //@ts-ignore
      this.createStartDate = null
      //@ts-ignore
      this.deleteBtnShow = false;
      //@ts-ignore
      this.selectedId = 0
      //@ts-ignore
      this.isDialogActive = true
      //@ts-ignore
      await this.$nextTick()
      //@ts-ignore
      ;(<MaintenancePlanDialog>this.$refs.maintenancePlanDialog).showDialog()
    },
    onExportClick: function () {
      // //@ts-ignore
      // let scheduleObj = this.$refs.ScheduleObj
      // let exportValues = {
      //   fields: ['Id', 'FullName', 'ScheduledTime', 'ExpectedEndTime', 'DeviceName']
      // };
      // //@ts-ignore
      // scheduleObj.exportToExcel(exportValues)
      
      const dateMoment = new Date();
      let excelWorkbook = new ExcelJS.Workbook();
      excelWorkbook.creator = 'CFM';
      excelWorkbook.created = dateMoment;
      excelWorkbook.calcProperties.fullCalcOnLoad = true;
      excelWorkbook.views = [
        {
          x: 0,
          y: 0,
          width: 10000,
          height: 20000,
          firstSheet: 0,
          activeTab: 1,
          visibility: 'visible',
        },
      ];

      const reportColumns = [
        { key: "Id", header: "İş Gurubu", width: 20 },
        { key: "FullName", header: "Full Name", width: 20 },
        { key: "ScheduledTime", header: "Başlangıç Tarihi", width: 20, style: { numFmt: 'dd/mm/yyyy HH:mm' } },
        { key: "ExpectedEndTime", header: "Bitiş Tarihi", width: 20, style: { numFmt: 'dd/mm/yyyy HH:mm' } },
        { key: "DeviceName", header: "Kapasite Ekipmanı", width: 20 },
      ]

      const reportRows = this.eventSettings.dataSource.map(row => {
        return {
          Id: row.Id,
          FullName: row.FullName,
          ScheduledTime: row.ScheduledTime ? dateFormat.viewDateTimeFormatTZ(row.ScheduledTime) : null,
          ExpectedEndTime: row.ExpectedEndTime ? dateFormat.viewDateTimeFormatTZ(row.ExpectedEndTime) : null,
          DeviceName: row.DeviceName,
        };
      });

      let context = [
        {
          sheetName: "Schedule",
          sheetData: reportRows,
          columnName: reportColumns
        }
      ];

      context.forEach((contextItem) => {
        // Add Work Sheet
        let sheet = excelWorkbook.addWorksheet(contextItem.sheetName, {});
        sheet.columns = contextItem.columnName;

        // Add Data 
        contextItem.sheetData.forEach((row) => {
          sheet.addRow(row);
        });

        // Add Header Style
        sheet.eachRow((sheetRow, sheetRowNumber) => {
          if (sheetRowNumber == 1) {
            sheet.autoFilter = `${sheet.getColumn(1).letter + "1:" + sheet.getColumn(sheet.columns.length).letter + "1"}`;
            sheetRow.font = { bold: true, size: 12 };
            sheetRow.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '0042a5f5' } };
          }
          else if (sheetRow.getCell(10).value === "No") {
            sheetRow.font = { color: { argb: '00ff0000'}, bold: true, size: 10 };
          }
          else {
            sheetRow.font = { bold: false, size: 10 };
          }

          sheetRow.commit();
        });
      });

      excelWorkbook.xlsx.writeBuffer().then((buffer) => {
        const uint8Array = new Uint8Array(buffer);
        this.saveByteArray(`Schedule.xlsx`, uint8Array);
      });
    },
    saveByteArray(reportName: string, byte: Uint8Array) {
      const blob = new Blob([byte], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      if (navigator.appVersion.toString().indexOf('.NET') > 0) window.navigator.msSaveBlob(blob, reportName);
      else {
        const link = document.createElementNS('http://www.w3.org/1999/xhtml', 'a') as HTMLAnchorElement;
        link.href = URL.createObjectURL(blob);
        link.download = reportName;
        link.click();
      }
    },
    onPrintIconClick: function () {
      //@ts-ignore
      let scheduleObj = this.$refs.ScheduleObj
      //@ts-ignore
      scheduleObj.print()
    },
  },
}
